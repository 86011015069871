import axios from 'axios'
// const baseUrl = 'http://sanyaoca.lingji.vip/';
const baseUrl = process.env.VUE_APP_BASE_API;

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    // 如果有token将请求时携带token
    config.headers["x-token"] = localStorage.getItem('token')
        ? localStorage.getItem('token'):'';
    // 在发送请求之前做些什么
    console.log('config',config)
    return config
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
});


const request = ({url,data={},method = 'post'})=>{ 
    return new Promise((resolve,reject)=>{
        axios({
            url:baseUrl+url,
            // header:{
            //     'Content-Type': 'application/json'
            // },
            method:method,
            data:data
        })
        .then(res=>{
            // 成功
            resolve(res);
        })
        .catch(res=>{
            // 失败
            reject(res)
        })
    })
}



export default request