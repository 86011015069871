import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入vant
import { Popup,Dialog,Toast,DropdownMenu, DropdownItem,Button,Loading,ActionSheet,Popover,Icon,Cell, CellGroup,Checkbox, CheckboxGroup,Slider,Stepper,RadioGroup, Radio,Field ,Tag,Picker,Notify   } from 'vant';
import 'vant/lib/index.css';
// 引入请求函数
import request from "@/tools/request";
Vue.config.productionTip = false;
//全局注册，使用方法为:this.$request
Vue.prototype.$request = request
// 引入弹框组件
Vue.use(Popup);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Button);
Vue.use(Loading);
Vue.use(ActionSheet);
Vue.use(Popover);
Vue.use(Icon);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Slider);
Vue.use(Stepper);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Field);
Vue.use(Tag);
Vue.use(Picker);
Vue.use(Notify);

import vcolorpicker from 'vcolorpicker'
Vue.use(vcolorpicker)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
