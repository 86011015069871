<template>
  <div>
    <div class="row">
      <van-radio-group v-model="writeType" direction="horizontal">
        <van-radio :name="1">输入</van-radio>
        <van-radio :name="2">手写</van-radio>
      </van-radio-group>
    </div>
    <div class="row" v-if="writeType==1">
      <textarea class="text-content" v-model="message" rows="2" :style="{color:color,fontFamily:fontFamily}" placeholder="请输入内容"></textarea>
      <!-- <van-field
        style="border:1px solid #000;border-radius:6px"
        v-model="message"
        rows="2"
        :style="{color:color,fontSize: fontSize+'px',fontFamily:fontFamily}"
        type="textarea"
        clearable
        placeholder="请输入内容"
      /> -->
      <div style="margin:10px 0 20px">
        <van-button round type="info" size="small" @click="setDate">当前日期</van-button>
      </div>
      <van-cell-group>
        <van-cell title="字体" is-link :value="fontFamilyName" @click="showFontFamily=!showFontFamily"></van-cell>
        <!-- <van-cell title="字号" is-link :value="fontSizeName"  @click="showFontSize=!showFontSize"></van-cell> -->
      </van-cell-group>
      <van-popup v-model="showFontFamily" round position="bottom">
        <van-picker
          show-toolbar
          title="字体"
          value-key="label"
          :columns="fontFamilyOptions"
          :default-index="defaultFontFamilyIndex"
          @confirm="onConfirmFontFamily"
          @cancel="showFontFamily=false"
        />
      </van-popup>
      <!-- <van-popup v-model="showFontSize" round position="bottom">
        <van-picker
          show-toolbar
          title="字号"
          value-key="label"
          :columns="fontSizeOptions"
          :default-index="defaultFontSizeIndex"
          @confirm="onConfirmFontSize"
          @cancel="showFontSize=false"
        />
      </van-popup> -->
    </div>
    <div class="row" v-if="writeType==2">
      <vue-esign 
        ref="esign"
        class="mySign"
        :width='width' 
        :height="height"
        :isCrop="true"
        :lineWidth="lineWidth"
        :lineColor="color"
      />
      <van-cell-group>
        <van-cell title="线条粗细">
          <template #default>
            <van-stepper v-model="lineWidth"/>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    
    <div style="margin-top:10px">
      <div class="color-picker">
        <div class="color-picker-item" :style="{background:item}" v-for="(item,index) in colorList" :key="index" @click="color=item">
          <van-icon v-if="item==color" name="success" />
        </div>
      </div>
      
      <div class="write-btn">
        <van-button size="small" type="warning" @click="reset">重写</van-button>
        <van-button size="small" type="danger" @click="confirm('add')">仅添加</van-button>
        <van-button size="small" type="info" @click="confirm('confirm')">盖章</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import vueEsign from 'vue-esign'
import moment from 'moment'
import {textToImg,getBase64} from '@/tools/image'
export default {
  components: {
    vueEsign
  },
  data(){
    return {
      writeType: 1,
      message:'',
      width:800,
      height:400,
      color:'#000000',
      lineWidth:6,
      colorList:['#000000','#FF3B3B','#FFEF7D','#CC68F8','#8B8AEE','#8AEEB1'],
      // fontSizeOptions: [
      //   // { value: 58, label: '初号' },
      //   // { value: 48, label: '小初' },
      //   { value: 34, label: '一号' },
      //   { value: 32, label: '小一' },
      //   { value: 30, label: '二号' },
      //   { value: 24, label: '小二' },
      //   { value: 22, label: '三号' },
      //   { value: 20, label: '小三' },
      //   { value: 18, label: '四号' },
      //   { value: 16, label: '小四' },
      //   { value: 14, label: '五号' },
      //   { value: 12, label: '小五' },
      //   { value: 10, label: '六号' }
      // ],
      fontFamilyOptions: [
        { value: 'SimSun', label: '宋体' },
        { value: 'SimHei', label: '黑体' },
        { value: 'MicrosoftYaHei', label: '微软雅黑' },
        { value: 'NSimSun', label: '新宋体' },
        { value: 'MingLiU', label: '细明体' },
        { value: 'FangSong', label: '仿宋' },
        { value: 'KaiTi', label: '楷体' }
      ],
      fontFamily: 'MicrosoftYaHei',
      fontSize: 100,
      showFontFamily: false,
      showFontSize: false,
      defaultFontFamilyIndex:0,
      defaultFontSizeIndex:0,
    }
  },
  computed:{
    fontFamilyName(){
      const obj = this.fontFamilyOptions.find(x=>x.value===this.fontFamily)
      if(obj){
        return obj.label
      }
    },
    fontSizeName(){
      const obj = this.fontSizeOptions.find(x=>x.value===this.fontSize)
      if(obj){
        return obj.label
      }
    },
  },
  created(){
    this.defaultFontFamilyIndex = this.fontFamilyOptions.findIndex(x=>x.value==this.fontFamily)
    // this.defaultFontSizeIndex = this.fontSizeOptions.findIndex(x=>x.value==this.fontSize)
  },
  methods:{
    confirm(type){
      if(this.writeType==1){
        if(this.message==''){
          this.$notify({
            title: '提示',
            message: '请输入内容',
            type: 'warning'
          });
          return
        }
        const { url, width, height } = textToImg(this.message, this.fontSize, this.color, this.fontFamily)
        
        console.log('gggg',url,width,height)

        this.$emit('confirm',{type:type,url:url,width:width,height:height})
      }
      else if(this.writeType==2){
        this.$refs.esign.generate().then(async (res)=>{
          const { width,height } = await getBase64(res)
          this.$emit('confirm',{type:type,url:res,width:width,height:height})
        }).catch(()=>{
          // 画布没有签字时提示
            this.$notify({
              title: '提示',
              message: '请签字',
              type: 'warning'
            });
        })
      }
    },
    reset(){
      this.$refs.esign.reset()
    },
    onConfirmFontFamily(value, index){
      this.fontFamily = this.fontFamilyOptions[index].value
      this.showFontFamily = false
    },
    onConfirmFontSize(value, index){
      this.fontSize = this.fontSizeOptions[index].value
      this.showFontSize = false
    },
    setDate(){
      this.message += moment().format('YYYY-MM-DD')
    }
  }
}
</script>
<style lang="scss" scoped>
.row{
  margin: 10px 0;
}
.mySign{
  border: 1px dashed #000;
}
.write-item{
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  label{
    margin-right: 10px;
  }
}
.text-content{
  border:1px solid #000;
  border-radius:6px;
  padding: 10px;
  width: 93%;
}
.color-picker{
  display: flex;
  margin:10px 0;
  &-item{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 10px;
    i{
      font-size: 30px;
      color: #fff;
    }
  }
}
.write-btn{
  margin: 20px 0;
  display: flex;
  justify-content: space-around;
  .van-button{
    flex: auto;
    & + .van-button{
      margin-left: 6px;
    }
  }
}
</style>