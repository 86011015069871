export function rotateBase64Img(src,edg, callback) {
  var canvas = document.createElement("canvas");
  var ctx = canvas.getContext("2d");
  var imgW;//图片宽度
  var imgH;//图片高度
  var size;//canvas初始大小
  if (edg % 90 != 0) {
      console.error("旋转角度必须是90的倍数!");
      throw '旋转角度必须是90的倍数!';
  }
  (edg < 0) && (edg = (edg % 360) + 360)
  const quadrant = (edg / 90) % 4; //旋转象限
  const cutCoor = {sx: 0, sy: 0, ex: 0, ey: 0}; //裁剪坐标
  var image = new Image();
  image.crossOrigin = "anonymous"
  image.src = src;
  image.onload = function () {
      imgW = image.width;
      imgH = image.height;
      size = imgW > imgH ? imgW : imgH;
      canvas.width = size * 2;
      canvas.height = size * 2;
      switch (quadrant) {
          case 0:
              cutCoor.sx = size;
              cutCoor.sy = size;
              cutCoor.ex = size + imgW;
              cutCoor.ey = size + imgH;
              break;
          case 1:
              cutCoor.sx = size - imgH;
              cutCoor.sy = size;
              cutCoor.ex = size;
              cutCoor.ey = size + imgW;
              break;
          case 2:
              cutCoor.sx = size - imgW;
              cutCoor.sy = size - imgH;
              cutCoor.ex = size;
              cutCoor.ey = size;
              break;
          case 3:
              cutCoor.sx = size;
              cutCoor.sy = size - imgW;
              cutCoor.ex = size + imgH;
              cutCoor.ey = size + imgW;
              break;
      }
      ctx.translate(size, size);
      ctx.rotate(edg * Math.PI / 180);
      ctx.drawImage(image, 0, 0);
      var imgData = ctx.getImageData(cutCoor.sx, cutCoor.sy, cutCoor.ex, cutCoor.ey);
      if (quadrant % 2 == 0) {
          canvas.width = imgW;
          canvas.height = imgH;
      } else {
          canvas.width = imgH;
          canvas.height = imgW;
      }
      ctx.putImageData(imgData, 0, 0);
      callback({url:canvas.toDataURL(),width:imgW,height:imgH})
  };
}

export function textToImg(text, fontsize, fontcolor, family) {
  var canvas = document.createElement('canvas')
  // 小于32字加1  小于60字加2  小于80字加4    小于100字加6

  var buHeight = 0
  if (fontsize <= 32) {
    buHeight = 1
  } else if (fontsize > 32 && fontsize <= 60) {
    buHeight = 2
  } else if (fontsize > 60 && fontsize <= 80) {
    buHeight = 4
  } else if (fontsize > 80 && fontsize <= 100) {
    buHeight = 6
  } else if (fontsize > 100) {
    buHeight = 10
  }
  // 对于g j 等有时会有遮挡，这里增加一些高度
  canvas.height = fontsize + buHeight
  var context = canvas.getContext('2d')
  // 擦除(0,0)位置大小为200x200的矩形，擦除的意思是把该区域变为透明
  context.clearRect(0, 0, canvas.width, canvas.height)
  context.fillStyle = fontcolor
  context.font = fontsize + 'px ' + family
  // top（顶部对齐） hanging（悬挂） middle（中间对齐） bottom（底部对齐） alphabetic是默认值
  context.textBaseline = 'middle'
  context.fillText(text, 0, fontsize / 2)

  // 改变大小后，重新设置一次文字
  canvas.width = context.measureText(text).width
  context.fillStyle = fontcolor
  context.font = fontsize + 'px ' + family
  context.textBaseline = 'middle'
  context.fillText(text, 0, fontsize / 2)

  var dataUrl = canvas.toDataURL('image/png')// 注意这里背景透明的话，需要使用png
  return { url: dataUrl, width: canvas.width, height: canvas.height }
}

export function getBase64 (img) {
  const image = new Image()
  image.crossOrigin = ''
  image.src = img
  return new Promise((resolve, reject) => {
    image.onload = function () {
      const { width, height } = image
      resolve({ width, height })
    }
  })
}