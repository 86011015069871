<template>
  <!-- <div ref="item_box" @touchstart="down" @touchmove="move" @touchend="end" >  -->
    <!-- <div > -->
        <vue-drag-resize :isResizable="item.type==2" :w="vw" :h="vh" :x="left" :y="top"  @resizing="resize" @dragging="resize" :minw="1" :minh="1" :sticks="['tl','tr','br','bl']" @dragstop="handel" @activated="activated" @deactivated="deactivated" @clicked="onClicked">
        <img :src="item.type===1?getUrl(item.seal_img):item.seal_img" alt="">
        <div v-if='showDel' class="delete" >
          <span class="del">删除</span>
          <span v-if="item.type==2" class="rotate">旋转</span>
        </div>
      </vue-drag-resize>
      
    <!-- </div> -->
    <!-- <img v-if="showDel" @click.prevent="delSelectSeal(index)" class="icon" src="/close.png" alt=""> -->
  <!-- </div> -->
</template>
<script>
import VueDragResize from "vue-drag-resize"
import {rotateBase64Img} from '@/tools/image'

export default {
  props: {
    item: {
      type: Object,
      default: {}
    },
    index: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 100
    },
    height: {
      type: Number,
      default: 100
    },
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    widthPx: {
      type: Number
    },
    heightPx: {
      type: Number
    }
  },
  components:{VueDragResize},
  created(){
    console.log('created')
    this.vw = this.widthPx
    this.vh = this.heightPx
    this.top = this.y
    this.left = this.x
  },
  mounted(){
    console.log('mounted')
  },
  data(){
    return {
      showDel: false,
      actions: [
        { text: '删除', icon: 'close' },
        { text: '旋转', icon: 'close' },
      ],
      vw: 0,
      vh: 0,
      top: 0,
      left: 0
    }
  },
  methods:{
    resize(newRect) {
      this.showDel = false
      console.log('大小变化',newRect,this.item,this.index)
      this.$emit('changeSize',newRect)
    },
    onClicked(e){
      switch(e.target.className){
        case 'del':
          this.$emit('delSelectSeal')
          break;
        case 'rotate':
          rotateBase64Img(this.item.seal_img,90,({url,width,height})=>{
            this.item.seal_img = url
            this.$emit('rotateSeal')
          })
          break;
      }
    },
    getUrl(url){
      return process.env.VUE_APP_BASE_API + url
    },
    handel(){
      this.showDel = true
      // this.$emit('handel', this.index)
    },
    activated(){
      this.showDel = true
    },
    deactivated(){
      this.showDel = false
    }
  }
}
</script>
<style lang="scss" scoped>
.icon{
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 999;
  width: 20px;
  height: 20px;
}
img{
  width: 100%;
  height: 100%;
}
.delete {
  // width: 90px;
  padding: 2px;
  position: absolute;
  bottom: -34px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 6px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  background-color: hsla(0, 0%, 4%, .6);
  cursor: pointer;
  display: flex;
  span{
    display: inline-block;
    width: 40px;
    line-height: 26px;
    & + span{
      border-left: 1px solid #fff;
    }
  }

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: -12px;
    width: 0;
    height: 0;
    border: 6px solid hsla(0, 0%, 4%, .6);
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    transform: translateX(-50%);
  }
}
</style>