<template>
    <div>
        <div @click="showPopup">展开</div>
        <van-popup
            v-model="show"
            closeable
            position="bottom"
            :style="{ height: '30%' }">内容
        </van-popup>
    </div>
</template>

<script>
    export default {
        name: "Demo",
        data(){
            return{
                show:false

            }
        },
        methods:{
            showPopup(){
                this.show = true
            }
        }
    }
</script>

<style scoped>

</style>