<template>
  <div class="home">
    <div class="top_menus">
      <!-- <div style="display:flex">
        <div class="label_tips">
          <p>
            当前文件:
          </p>
        </div>
        <div class="file_list_menu">
          <van-dropdown-menu>
            <van-dropdown-item v-model="file_id" :options="file_option" @change="changeFile" />
          </van-dropdown-menu>
        </div>
      </div> -->
      <div v-show="!isLoading" style="display:flex;align-items:center; width:50%;margin:0 20px">
        <span style="font-size:14px">{{ scale*100 }}%</span> 
        <van-slider style="margin-left:10px" v-model="scale" :step="0.25" :max="4" :min="0.5" />
      </div>
      <div style="display:flex;flex:auto;justify-content:space-around;align-items:flex-end">
         <div v-if="!isLoading&&canEdit" @click="showTemplate = true" class="menu-item">
          <van-icon name="invitation" :size="24"  />
          <span>模板</span>
        </div>
        <div v-if="!isLoading&&canEdit" @click="showChangeFile = true" class="menu-item">
          <van-icon name="more-o" :size="24"  />
          <span>文件</span>
        </div>
        <div v-if="notMatch.length" @click="showNotseal = true" class="menu-item">
          <van-icon :badge="notMatch.length" :size="24" name="failure" />
          <span>未签章</span>
        </div>
      </div>
      <!-- <van-button v-if="canEdit" @click="showTemplate = true" type="info" size="small">切换文件</van-button> -->
      <!-- <van-button v-if="canEdit" @click="showTemplate = true" type="info" size="small">使用模板</van-button> -->
      <!-- <van-button v-if="notMatch.length" @click="showNotseal = true" type="danger" size="small">未签章页面</van-button> -->
    </div>
    <!-- <van-action-sheet v-model="showTemplate" :actions="actionList" description="请选择模板" @select="onSelect" /> -->
	<van-action-sheet style="max-height: 50%;" v-model="showNotseal" :actions="notMatch" description="点击跳转页面" @select="goPage"/>
    <div class="box_top"></div>
    <!-- pdf文件展示 -->
    <div v-if="isLoading" style="text-align:center;padding-top:30vh;height:60vh">
      <van-loading  type="spinner" color="#1989fa">加载文件中...</van-loading>
    </div>
    <div v-show="!isLoading" class="pdf-box" v-for="page in pages" :key="page"  @click="move($event, page)" :id="'wrapper_' + page" ref="agreemenContent">
      <canvas class="box" :id="'the-canvas' + page" ref="pdf"></canvas>
      <div  v-for="(item,index) in currentSealList" :key="item.id">
        <!-- 盖章列表 -->
        <seal-item v-if="!isLoading && item.page == page" :item="item" :index="index" @delSelectSeal="()=>delSelectSeal(index)" :key="key1" :x="item.x" :y="item.y" :widthPx="item.widthPx" :heightPx="item.heightPx" @changeSize="(newRect)=>changeSize(newRect,index)" @rotateSeal="()=>rotateSeal(index)">
        </seal-item>
      </div>
      
      <!-- <seal-item v-if="!isLoading && item.page == page"
        class="select_seal_img" v-for="(item,index) in currentSealList" :item="item" :index="index" @changeXY="changeXY" @delSelectSeal="()=>delSelectSeal(index)" :key="index"  :left="item.x" :top="item.y" :style="{left:(item.x) +'px',top:(item.y) +'px',width: mmConversionPx(item.width) * getScale(item.page,2)+'px',height:mmConversionPx(item.height) * getScale(item.page,2)+'px'}">
      </seal-item> -->
      <!-- <div  
        :style="{left:(item.x) +'px',top:(item.y) +'px',width: mmConversionPx(item.width) * getScale(item.page)+'px',height:mmConversionPx(item.height) * getScale(item.page)+'px'}"> 
        <img @click.prevent="handel(index)"  :src="getUrl(item.seal_img)" alt="">
        <img v-show="closeIsShowNum == index " @click.prevent="delSelectSeal(index)" class="icon" src="/close.png" alt="">
      </div> -->
    </div>
    <!-- 选中盖章的位置小点 -->
    <div v-if="!isLoading && showPoint" class="pointer" :style="{ top: clientMY + 'px', left: clientX + 'px' }"></div>
    <!-- 印章列表 -->
    <div class="popup_seal"  v-show="mySealIsShow">
      <van-popup
              :overlay="false"
              v-model="mySealIsShow"
              closeable
              position="bottom"
              :style="{ height: '150px',padding:'8px 0',boxSizing: 'border-box',overflow: 'visible',boxShadow:'0px -6px 10px 0px #888888' }">
        <div class="my_seal_label">
          请选择印章
        </div>
        <div class="my_seal_list">
          <div class="seal_box" v-for="i in mySealList" :key="i.id" @click="selectMySeal(i)">
            <img :src="getUrl(i.seal_img)" alt=""/>
          </div>
        </div>
      </van-popup>
    </div>
    <div style="height:44px">
      <van-button v-if="!isLoading && canEdit" type="primary" style="margin: auto;width: 96%;position: fixed;bottom: 0px;left:2%" block  @click="submitList">完成签署</van-button>
    </div>
    <!-- <div class="submit_btn" v-show="selectSealList.length > 0">完成签署</div> -->
	<!-- <div v-if="notMatch.length > 0" class="not-match-wrapper" @click="showNotseal = true">
		<van-icon name="warning-o" />
		<div>未签章</div>
	</div> -->
  <!-- 印章类型 -->
  <van-popup class="popup_seal_type" v-model="showType" closeable :overlay="false" position="bottom" :style="{boxShadow:'0px -6px 10px 0px #888888',padding:'12px 0' }">
      <div class="seal_type">
        <div class="seal_type_item" @click="openType(1)">
          <img src="/seal.png" alt="">
          <span>签章</span>
        </div>
        <div class="seal_type_item" @click="openType(2)">
          <img src="/qianming.png" alt="">
          <span>手写</span>
        </div>
      </div>
  </van-popup>
  <!-- 手写签列表 -->
  <div class="popup_seal"  v-show="tempSealIsShow">
    <van-popup
      :overlay="false"
      v-model="tempSealIsShow"
      closeable
      position="bottom"
      :style="{ padding:'8px 0',boxSizing: 'border-box',overflow: 'visible',boxShadow:'0px -6px 10px 0px #888888' }">
      <div class="my_seal_label">
        请选择临时章
      </div>
      <div class="temp_seal_list">
        <van-button v-if="tempSealList.length<4" icon="plus" type="info" @click="addWrite"></van-button>
        <div class="seal_box" v-for="(item,index) in tempSealList" :key="index" @click="selectMySeal(item)">
           <img :src="item.seal_img" alt=""/>
           <van-icon name="close" @click.stop="delTemp(index)" />
        </div>
        <!-- <div class="seal_box" v-for="i in mySealList" :key="i.id" @click="selectMySeal(i)">
          <img :src="getUrl(i.seal_img)" alt=""/>
        </div> -->
      </div>
    </van-popup>
  </div>
  <van-popup
      v-model="writePop"
      position="center" :style="{width:'90vw',padding:'10px',borderRadius:'6px'}"
      >
      <write ref="writeSeal" @confirm="confirmWrite"></write>
    </van-popup>
  <!-- 模板列表 -->
  <van-popup v-model="showTemplate" position="right" :style="{height:'100vh',width:'50vw'}">
    <div style="padding:20px 0">
      <div style="font-size:18px;font-weight:bold;text-align:center;margin-bottom:20px">请选择模板</div>
      <div style="max-height:74vh;overflow-y: auto;">
        <van-checkbox-group v-model="selectTemplate">
          <van-cell-group>
            <van-cell
              v-for="(item,index) in templateList" :key="index" :title="item.title" :label="item.content" @click="toggle(index)"
            >
              <template #right-icon>
                <van-checkbox :name="item.id" ref="checkboxes" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
      </div>
      <div style="padding:0 10px;margin-top:20px">
        <van-button type="info" block @click="onSelect">使用模板</van-button>
      </div>
    </div>
  </van-popup>
  <!-- 我的文件 -->
  <van-popup v-model="showChangeFile" position="right" :style="{height:'100vh',width:'60vw'}">
    <div style="padding:20px 0">
      <div style="font-size:18px;font-weight:bold;text-align:center;margin-bottom:20px">请选择文件</div>
      <div>
        <van-cell @click="onSelectFile(item)" v-for="(item,index) in fileList" :key="index" :icon="item.id==file_id?'success':''" :title="item.title" is-link />
      </div>
    </div>
  </van-popup>
  </div>
</template>

<script>
// @ is an alias to /src
import pdfjs from 'pdfjs-dist'
import SealItem from '@/components/SealItem'
import Write from '@/components/write'

export default {
  name: 'Seal',
  data() {
    return {
      file_id: 0,
      // file_option: [
      //   // { text: '全部商品', value: 0 }
      // ],
      // 我的印章列表
      mySealList:[],
      templateList:[],
      actionList:[],
      mySealIsShow: false,
      // 选择的章数组
      selectSealList:[],
      // currentSealList:[],// 当前页的印章列表
      closeIsShowNum: -1,
      pdfDoc: "",
      pages: 1,
      // fileUrl: '',
      // 记录鼠标的事件
      mouseMoveList: [],
      // 记录第几页
      mouseMovePage: 0,
      clientY: 0,
      clientX: 0,
      clientMY:0,
      sealList: [],
      // 接口数据
      // 文件列表
      electron:{},
      fileList:[],
      scale:1,
      scaleRatio: 1.0, // 缩放比例
      isLoading: true,
      showPoint: false,
      isFlag: false,
      showTemplate: false,
      userInfo:{},
      showNotseal:false,
      showChangeFile:false,
      notMatch: [],// 没有签章的页面
      selectTemplate:[],
      isChange: false,
      showType:false,
      tempSealIsShow: false,
      writePop: false,
      tempSealList:[],
      key1: Math.random()
    }
  },
  components: {
    SealItem,Write
  },
  computed:{
    currentSealList(){
      console.log('重新生成',[...this.selectSealList])
      let obj = this.selectSealList.find(x=>x.fileId == this.file_id)
      if(obj){
        return obj.sealPos
      }
      return []
    },
    canEdit() {
      return this.electron.current_signatory === this.userInfo.id && this.electron.status < 5
    }
  },
  watch:{
    // currentSealList(){
    //   let obj = this.selectSealList.find(item => item.fileId == this.file_id)
    //   if(!obj){
    //     obj = {fileId :this.file_id, sealPos:[]}
    //     this.selectSealList.push(obj)
    //   }
    //   obj.sealPos = [...this.currentSealList]
    //   console.log('this.selectSealList',this.selectSealList)
    // }
    scale(new1, old1) {
      if(!this.isChange){
        this.showPoint = false
        this.tempSealIsShow = false
        this.showTemplate = false
        this.showType = false

        this.scaleRatio = new1 / old1
        // console.log('fff', this.scaleRatio)
        document.querySelectorAll('.box').forEach(item => {
          item.style.width = `${new1 * 100}%`
        })
        this.currentSealList.forEach(item => {
          item.x *= this.scaleRatio
          console.log('y1',item.y,this.scaleRatio)
          item.y *= this.scaleRatio
          console.log('y2',item.y)
          
          item.widthPx *= this.scaleRatio
          item.heightPx *= this.scaleRatio
          // item.widthPx /= this.getScale(item.page)
          // item.heightPx /= this.getScale(item.page)
          // item.pdf_pos_x = this.pxConversionMm(item.x / this.getScale(item.page))
          // item.pdf_pos_y = this.pxConversionMm(item.y / this.getScale(item.page))
        })
        this.key1 = Math.random()
        console.log('this.currentSealList',this.currentSealList)
        
      }
        // console.log('a1', [...this.seal_info])
        // document.querySelectorAll('.mark').forEach((item) => {
        //   const child = this.getObjById(item.dataset.domid)
        //   child.pos_x *= this.scaleRatio
        //   child.pos_y *= this.scaleRatio
        //   child.pdf_pos_x = this.pxConversionMm(parseInt(child.pos_x) / this.getScale(child.page))
        //   child.pdf_pos_y = this.pxConversionMm(parseInt(child.pos_y) / this.getScale(child.page))
        //   // this.saveSealPos(item.dataset.domid, (parseInt(item.style.left) * this.scaleRatio), (parseInt(item.style.top) * this.scaleRatio))
        // })
        // console.log('a2', [...this.seal_info])

        // this.$nextTick(() => {
        //   this.resetDom()
        // })
    },
    selectSealList:{
      handler() {
        this.SplicenotMatch()
      },
      deep: true
    }
	
  },
  async created() {
    localStorage.setItem('token', this.$route.query.token)
    this.electronId = this.$route.query.electronId
    await this.getUserInfo()
    this.getSealList();
    this.getFileList();
    this.getMyTemplate()
  },
  mounted() {
    document.addEventListener('UniAppJSBridgeReady', function() {
      uni.getEnv(function(res) {
          console.log('当前环境：' + JSON.stringify(res));
      });
    })
  },
  methods: {
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    //未签署列表跳到指定页面
    goPage(action,index){
      window.scrollTo({top:document.getElementById(`wrapper_${action.index}`).offsetTop - 55, behavior: 'smooth'})
      this.showNotseal = false;	 
    },
    pxConversionMm(value) {
      return value * 25.4 / 72
    },
    mmConversionPx(value) {
      return value * 72 / 25.4
    },
    getScale(page,type) {
      const canvas = document.getElementById('the-canvas' + page)
	    return canvas.clientWidth / canvas.width
    },
    getPageHeight(page) {
      const canvas = document.getElementById('the-canvas' + page)
      return canvas.parentNode.offsetHeight
    },
    getPageWidth(page) {
      const canvas = document.getElementById('the-canvas' + page)
      return canvas.parentNode.offsetWidth
    },
    getTopHeight(page) {
      let height = 0
      for (let i = 1; i <= page; i++) {
        height += this.getPageHeight(i) + 20
      }
      return height
    },
    getSealPos(){
      let obj = this.selectSealList.find(x=>x.fileId == this.file_id)
      if(!obj){
        obj = {fileId :this.file_id, sealPos:[]}
        this.selectSealList.push(obj)
      }
      return obj.sealPos
    },
    getUrl(url){
      return process.env.VUE_APP_BASE_API + url
    },
    // 获取我的印章
    async getSealList(){
      try {
        let res = await this.$request({
          url:'/admin/Seal/getAll',
        });
        if(res.data.code===20000){
          this.mySealList = res.data.data.map(x=>{
            x.type = 1 
            return x
          });
        }else{
          this.$toast(res.data.message)
        }
      } catch (error) {
        console.log('erro1r',error)
      }
      
    },
    async getUserInfo(){
      try {
        let res = await this.$request({
          url:'/admin/Account/info',
        });
        if(res.data.code===20000){
          this.userInfo = res.data.data
        }
      } catch (error) {
        console.log('erro1r',error)
      }
      
    },
    async getMyTemplate() {
       try {
        const that = this;
        let res = await that.$request({
          url:'/admin/Mould/getMyTemplate',
        });
        if(res.data.code===20000){
          that.templateList = res.data.data;
          // that.actionList = that.templateList.map(x=>{
          //   let obj = {
          //     name:x.title
          //   }
          //   if(x.content){
          //     obj.subname = x.content
          //   }
          //   return obj
          // });
        }else{
          this.$toast(res.data.message)
        }
      } catch (error) {
        console.log('erro1r',error)
      }
    },
    onSelect(){
      if(this.selectTemplate.length===0){
        this.$toast('请选择模板')
        return
      }
      this.$dialog.confirm({
        message: '是否确认使用模板？',
      })
      .then(() => {
        let isSeal = false
        this.selectTemplate.forEach(item=>{
          const { seal_coordinate, width } = this.templateList.find(x=>x.id===item)
          const seal_obj = JSON.parse(seal_coordinate)
        
          for (let index = 1; index <= this.pages; index++) {
            const canvas = document.getElementById('the-canvas' + index)
            const pageMM = this.pxConversionMm(parseInt(canvas.width))
            console.log('pageMM', pageMM)
            const error = 50 // 误差范围
            if (pageMM < width + error && pageMM > width - error) { // 如果符合模板尺寸
              isSeal = true
              // seal_obj.forEach(x => {
              //   console.log('xxxxxx',x)
              //   // const seal = this.mySealList.find(y=>y.id == x.sealId)

              //   // this.addPos(seal,this.mmConversionPx(x.pdf_pos_x * this.getScale(index)),this.mmConversionPx(x.pdf_pos_y * this.getScale(index)),index)
              // })
            }else{
              // 如果未签署页面的数组长度为0，添加数组数据
              if (!this.notMatch.some(x => x.index === index)) {
                  this.notMatch.push({ index: index })
              }
            }
          }
        })
        
        // 使用模板后，去除模板盖章的页面
        this.SplicenotMatch()
        if (isSeal) {
          this.$toast('使用模板成功')
        } else {
          this.$toast('模板尺寸不符合，暂无可用模板')
        }
        this.showTemplate = false
      })
    },
    // 去除已经有印章的页面
    SplicenotMatch() {
      let obj = this.selectSealList.find(x=>x.fileId == this.file_id)
          const arr = []
        const that = this
        if(obj){
        this.notMatch.forEach(item => {
              const page = item.index
          if (!obj['sealPos'].some(x => x.page === item.index)) {
              let item = {
                name:'第'+page+'页',
              index:page
              }
            arr.push(item)
          }
          
        }) 
        }else{
          this.notMatch.forEach(item => {
                let items = {
                  name:'第'+item.index+'页',
                index:item.index
                }
              arr.push(items)
          })
        }
        this.notMatch = arr 
    },
    // 获取签署文件
    async getFileList(){
      try {
        let res = await this.$request({
          url:'/admin/Electron/getElectronDetail',
          data:{
            id: this.electronId
          }
        });
        if(res.data.code===20000){
          this.electron = res.data.data;
          this.fileList = this.electron.fileList;
          this.file_id = this.fileList[0].id;
          // this.file_option = this.fileList.map(x=>{
          //   return {
          //     text: x.title,
          //     value: x.id,
          //   }
          // })
          this.changeFile()
        }else{
          this.$toast(res.data.message)
        }
      } catch (error) {
        console.log('erro2r',error)
      }
      
    },
    // changeXY(index){
    //   let obj = this.currentSealList[index]
    //   // console.log('obj',obj)
    //   obj.pdf_pos_x = this.pxConversionMm(obj.x / this.getScale(obj.page))
    //   obj.pdf_pos_y = this.pxConversionMm(obj.y / this.getScale(obj.page))
    // },
    onSelectFile(item){
      if(this.file_id!==item.id){
        this.isChange = true
        this.scaleRatio = 1 / this.scale
        this.scale = 1
        // console.log('ratio',this.scaleRatio,[...this.currentSealList])
        this.currentSealList.forEach(item => {
          item.x *= this.scaleRatio
          item.y *= this.scaleRatio
          // item.pdf_pos_x = this.pxConversionMm(item.x / this.getScale(item.page))
          // item.pdf_pos_y = this.pxConversionMm(item.y / this.getScale(item.page))
        })
        this.showChangeFile = false
        this.showPoint = false
        setTimeout(async ()=>{
          this.file_id = item.id
          await this.changeFile()
          this.isChange = false
        },500)
        // this.isChange = false
      }
    },
    async changeFile(){
      await this._loadFile(this.getUrl(this.fileList.find(x=>x.id==this.file_id).file));
      this.notMatch = []
    },
    // 完成签署
    async submitList(){
      if(this.isFlag){
        return
      }
     

      if(this.selectSealList.length!=this.fileList.length){
        this.$toast('还有文件未签署');
        return
      }

      
      
      try{
        // this.selectSealList.forEach(x=>{
        //   x.sealPos.forEach(obj=>{
        //     obj.pdf_pos_x = this.pxConversionMm(obj.x / this.getScale(obj.page))
        //     obj.pdf_pos_y = this.pxConversionMm(obj.y / this.getScale(obj.page))
        //   })
        // })
        console.log('ddddd',this.selectSealList)
        // return
        this.isFlag = true;
        this.$toast.loading({
          message: '签署中...',
          forbidClick: true,
          loadingType: 'spinner',
          duration: 0
        });

        let res = await this.$request({
          url:'/admin/Electron/confirmElectron',
          data:{electron_id:this.electronId,seal_info:this.selectSealList}
        });
        if(res.data.code===20000){
          this.$toast.success('签署成功');
          setTimeout(()=>{
            uni.switchTab({
              url: '/pages/index/index'
            });
          },1000)
        }else{
          this.$toast.fail(res.data.message);
        }
        //跳转回小程序
        
      }catch(e){
        this.$toast.fail('签署失败');
      }finally{
        this.isFlag = false;
      }
    },
    addPos(seal_info,x,y,page){
      let obj = {
        // width: seal_info.width_mm,
        // height: seal_info.height_mm,
        widthPx: this.mmConversionPx(seal_info.width_mm * this.getScale(page)),
        heightPx: this.mmConversionPx(seal_info.height_mm * this.getScale(page)),
        seal_img: seal_info.seal_img,
        sealId: seal_info.id,
        x: x,
        y: y,
        type:seal_info.type,
        pdf_pos_x: this.pxConversionMm(x / this.getScale(page)),
        pdf_pos_y: this.pxConversionMm(y / this.getScale(page)),
        page: page,
        sealInfo: seal_info.type==1?{}:seal_info,
        id: this.guid()
      }
      console.log('obj1111',obj)
      this.getSealPos().push(obj);
    },
    changeSize(newRect,index){
      // console.log('ddddd')
      if(newRect.width>0 && newRect.height>0){
        const item = this.currentSealList[index]
        item.widthPx = newRect.width
        item.heightPx = newRect.height
        item.x = newRect.left
        item.y = newRect.top
        item.pdf_pos_x = this.pxConversionMm(item.x / this.getScale(item.page))
        item.pdf_pos_y = this.pxConversionMm(item.y / this.getScale(item.page))
        if(item.type==2){
          item.sealInfo.width_mm = this.pxConversionMm(item.widthPx / this.getScale(item.page))
          item.sealInfo.height_mm = this.pxConversionMm(item.heightPx / this.getScale(item.page)) 
        }
        console.log('item',item)
      }
    },
    // 选择印章-盖章
    selectMySeal(item){
      // const x = this.clientX - this.mmConversionPx(item.width_mm)  * this.getScale(this.mouseMovePage)/2 + 5
      // const y = this.clientY - this.mmConversionPx(item.height_mm) * this.getScale(this.mouseMovePage)/2 + 5
      const x = this.clientX
      const y = this.clientY
      // let obj = {
      //   width: width,
      //   seal_img: item.seal_img,
      //   sealId: item.id,
      //   x: x,
      //   y: y,
      //   pdf_pos_x: this.pxConversionMm(x / this.getScale(this.mouseMovePage)),
      //   pdf_pos_y: this.pxConversionMm(y / this.getScale(this.mouseMovePage)),
      //   page: this.mouseMovePage,
      // }
      // 将选择的印章存到数组中
      // this.getSealPos().push(obj);
      this.addPos(item,x,y,this.mouseMovePage);
      this.mySealIsShow = false;
      this.showPoint = false
    },
    handel(index){
      this.closeIsShowNum = index;
    },
    delSelectSeal(index){
      // console.log('aa',this.getSealPos())
      // console.log('bb',index)
      this.getSealPos().splice(index,1)
      // this.selectSealList = [...this.selectSealList]
      this.closeIsShowNum = -1
    },
    rotateSeal(index){
      const item = this.currentSealList[index]
      if(item.sealInfo.seal_img){
        item.sealInfo.seal_img = item.seal_img
      }
      console.log('旋转',item)
      // rotateBase64Img(item.seal_img,90,res=>{
      //   console.log('res',res)
      //   item.seal_img = res
      // })
    },
    // 鼠标移动事件
    move(e, page) {
      if(!this.canEdit){
        return
      }
      if(e.target.classList.length == 0 ||e.target.classList[0] == 'icon'){
        return
      }
      // this.mySealIsShow = true;
      if(!this.mySealIsShow && !this.tempSealIsShow){
         this.showType = true;
      }
      // that.mouseMoveList = e.changedTouches[0];
      this.mouseMovePage = page;
      // 获取当前的点击的第几页
      let wrapper = document.getElementById('wrapper_' + this.mouseMovePage);
      // 获取当前页元素距顶部的距离
      let boxTop = wrapper.getBoundingClientRect().top;
      let boxHeight = wrapper.getBoundingClientRect().height;
      // 当前鼠标点击的距离 - 距顶部的距离 = 当前鼠标在当前的页面点击的位置
      // console.log(22, canvas.getBoundingClientRect());
      this.clientX = e.clientX + document.scrollingElement.scrollLeft - 5;
      // 5 为当前小点大小的一半
      this.clientY = e.clientY - 5 - boxTop;
      this.clientMY = e.clientY - 5 - boxTop + 53;
      if (this.mouseMovePage > 1) {
        let Yheight =  this.totalheight(this.mouseMovePage);
            //that.clientMY = e.clientY + ((that.mouseMovePage - 1) * boxHeight) - boxTop + 53;
        this.clientMY = e.clientY + Yheight - boxTop + 53;
      }
      this.showPoint = true
    },
    //计算之前页面的总高度
    totalheight(page){
      console.log(page);
      let height = 0;
      for(let i=1;i<page;i++){
        height +=  document.getElementById('wrapper_' + i).getBoundingClientRect().height;
      }
      return height;
    },
    // 渲染pdf文件
    async _renderPage(num) {
      await this.pdfDoc.getPage(num).then(async (page) => {
        let canvas = document.getElementById('the-canvas' + num)
        let ctx = canvas.getContext('2d')
        // let dpr = window.devicePixelRatio || 1
        // let bsr = ctx.webkitBackingStorePixelRatio ||
        //   ctx.mozBackingStorePixelRatio ||
        //   ctx.msBackingStorePixelRatio ||
        //   ctx.oBackingStorePixelRatio ||
        //   ctx.backingStorePixelRatio || 1
        // let ratio = dpr / bsr
        let viewport = page.getViewport(1)
        canvas.width = viewport.width
        canvas.height = viewport.height
        // canvas.style.width = viewport.width + 'px'
        canvas.style.width = 100 + '%'  //设置宽度
        // canvas.style.height = 700 + 'px'  //设置高度，可以不设
        // ctx.setTransform(ratio, 0, 0, ratio, 0, 0)
        let renderContext = {
          canvasContext: ctx,
          viewport: viewport
        }
        page.render(renderContext)
        if (this.pages > num) {
          await this._renderPage(num + 1)
        }
      })
    },
    // 加载pdf文件
    async _loadFile(url) {
      this.isLoading = true
      await pdfjs.getDocument(url).then((pdf) => {
        this.pdfDoc = pdf
        this.pages = this.pdfDoc.numPages
        this.$nextTick(async () => {
          await this._renderPage(1)
          // this.currentSealList = []
          // 渲染签章
          // console.log('aaa',this.file_id)
          // const obj = this.selectSealList.find(x=>x.fileId==this.file_id)
          // if(obj){
          //   this.currentSealList = [... obj.sealPos ]
          // }
          setTimeout(() => {
            this.$forceUpdate()
          }, 1000)
          this.showPoint = false
          this.isLoading = false
        })
      })
    },
    openType(type){
      // this.showType = false
      if(type===1){
        this.mySealIsShow = true
      }else{
        if(this.tempSealList.length==0){
          this.writePop = true
        }
        this.tempSealIsShow = true
      }
    },
    addWrite(){
      this.writePop = true
      this.$nextTick(()=>{
        this.$refs.writeSeal.reset()
      })
    },
    guid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0
        var v = c === 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
      })
    },
    async confirmWrite(obj){
      const {url,width,height} = obj
      const data = {
        id:this.guid(),
        seal_img: url,
        width_mm: this.pxConversionMm(width),
        height_mm: this.pxConversionMm(height),
        type:2
      }
      this.tempSealList.push(data)
      if(obj.type=='confirm'){
        this.selectMySeal(data)
      }
      this.writePop = false
    },
    delTemp(index){
      this.tempSealList.splice(index,1)
    }
  }
}
</script>
<style lang="scss" scoped>

/deep/.van-dropdown-menu__bar{
        box-shadow: none;
}
.home{
  background: #f5f5f5;
  .top_menus{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    // padding-top: 3px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    background-color: #fff;
    // align-items: center;
    height: 50px;
    /*顶部提示信息*/
    .label_tips{
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      color: #ee0a24;
      display: flex;
      align-items: center;
      justify-content: center;

    }
    .file_list_menu{
      max-width:180px;
    }
    .menu-item{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      span{
        font-size: 12px;
      }
    }
  }
  .box_top{
    width: 100%;
    height: 50px;
    margin-bottom: 3px;
  }
  /*pdf文件展示区*/
  .pdf-box {
    position: relative;
    .box {
      // border: 1px solid #333;
      box-sizing: border-box;
    }
    .select_seal_img{
      position: absolute;
      // width: 100px;
      // height: 100px;
      z-index: 999;
      /*关闭按钮*/
      .icon{
        position: absolute;
        right: -5px;
        top: -5px;
        z-index: 999;
        width: 20px;
        height: 20px;
      }
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
  /*印章位置小点*/
  .pointer {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #333;
    position: absolute;
    z-index: 99;
  }
  /* 我的印章列表 */
  .popup_seal{
    width: 100%;
    /*height: 130px;*/
    padding: 8px 0;
    box-sizing: border-box;
    /deep/.van-popup__close-icon--top-right{
      top: 8px;
      right: 8px;
    }
    .my_seal_label{
      font-size: 18px;
      color: #333;
      width: 100%;
      box-sizing: border-box;
      padding: 0 30px;
      position: relative;
      margin-bottom: 8px;
      &::before{
        content: "";
        position: absolute;
        left: 10px;
        top: 50%;
        width: 5px;
        height: 80%;
        transform: translateY(-50%);
        border-radius: 8px;
        background-color: #42b983;
      }
    }
    .my_seal_list{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      /*justify-content: space-around;*/
      width: 100%;
      overflow-y: auto;
      height: 100px;
      /*position: fixed;*/
      /*bottom: 0;*/
      /*z-index: 999;*/
      /*left: 0;*/
      background-color: #fefefe;
      border-top: 1px solid #f4f4f4;
      box-sizing: border-box;
      .seal_box{
        width: 25%;
        height: 100%;
        box-sizing: border-box;
        border-right: 1px solid #f4f4f4;
        border-bottom: 1px solid #f4f4f4;
        display: flex;
        justify-content: center;
        align-items: center;
        &:nth-child(4n){
          border-right: none;
        }
        img{
          width: 80%;
          /*height: 90%;*/
        }
      }
    }
    .temp_seal_list{
      
      display: flex;
      align-items: center;
      padding: 10px;
      .van-button{
        margin-right: 10px;
      }
      .seal_box{
        position: relative;
        margin-right: 6px;
        width: 20%;
        height: 44px;
        border: 1px solid #f4f4f4;
        img{
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        i{
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }
	
  }
  .not-match-wrapper{
	color: #fff;
	background-color: #ff0000;
	border-radius: 10px;
	padding: 5px 9px;
  	position: fixed;
  	right: 2vw;
  	top: 10vh;
  	display: flex;
  	flex-direction: column;
  	align-items: center;
	i{
		font-size: 30px;
	}
  }
  .submit_btn{
    padding: 3px 8px;
    font-size: 13px;
    box-sizing: border-box;
    background-color: #42b983;
    color: #fff;
    border-radius: 8px;
  }
}
.popup_seal_type{
  /deep/.van-popup__close-icon--top-right{
      top: 8px;
      right: 8px;
    }
  .seal_type{
    display: flex;
    justify-content: space-around;
    font-size: 14px;
    &_item{
      display: flex;
      flex-direction: column;
      align-items: center;
      img{
        width:30px;
        height: 30px;
        margin-bottom: 6px;
      }

    }
  }
}


</style>
