import Vue from 'vue'
import VueRouter from 'vue-router'
import Seal from '../views/Seal.vue'
import Demo from "../views/Demo";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Seal
  },
  {
    path: '/demo',
    name: 'demo',
    component: Demo
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
