<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>
<script>
    import Seal from './views/Seal.vue'
    export default {
        name: 'HelloWorld',
        components: {
            Seal
        },
        data() {
            return {}
        },
        methods: {}
    }
</script>

<style scoped>

</style>
